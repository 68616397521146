import { useEffect, useRef, useState } from "react";

type BrandLogos = { logoUrls: string[] };

export default function BrandLogos({ logoUrls }: BrandLogos) {
  const brandsContainer = useRef<HTMLDivElement>(null);
  const [containerLeft, setContainerLeft] = useState(0);
  const [isScrolling, setIsScrolling] = useState(true);

  function stopScrolling() {
    setIsScrolling(false);
  }

  function startScrolling() {
    setIsScrolling(true);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (brandsContainer.current && isScrolling) {
        const maxScrollWidth = brandsContainer.current.offsetWidth / 2;

        setContainerLeft((prev) => {
          if (prev <= maxScrollWidth) return prev + 1;
          else return 0;
        });
      }
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, [isScrolling]);

  return (
    <>
      <div
        className="pointer-events-none relative h-20 overflow-x-scroll scroll-smooth [-ms-overflow-style:_none] [mask:_linear-gradient(to_right,_transparent,_white_10%_90%,_transparent)] [scrollbar-width:_none] [&::-webkit-scrollbar]:h-0 [&::-webkit-scrollbar]:w-0"
        // onMouseEnter={stopScrolling}
        // onMouseLeave={startScrolling}
      >
        <div
          className={`absolute flex`}
          ref={brandsContainer}
          style={{
            left: `-${containerLeft}px`,
          }}
        >
          <LogoSet
            logoUrls={logoUrls}
            className=""
            key="1"
            // onStartScrolling={startScrolling}
            // onStopScrolling={stopScrolling}
          />
          <LogoSet
            logoUrls={logoUrls}
            className=""
            key="2"
            // onStartScrolling={startScrolling}
            // onStopScrolling={stopScrolling}
          />
        </div>
      </div>
    </>
  );
}

type LogoSetProps = {
  logoUrls: string[];
  // onStartScrolling(): void;
  // onStopScrolling(): void;
} & React.HTMLAttributes<HTMLDivElement>;

function LogoSet(props: LogoSetProps) {
  return (
    <div
      className={`flex gap-x-16 ${props.className} min-w-max after:content-normal`}
    >
      {props.logoUrls.map((logoUrl) => (
        <div className="h-[5rem] w-[10rem]" key={logoUrl}>
          <img
            alt="collaborator"
            className="pointer-events-auto h-full w-full object-contain"
            src={logoUrl}
            // onMouseEnter={props.onStopScrolling}
            // onMouseLeave={props.onStartScrolling}
          />
        </div>
      ))}
    </div>
  );
}
